import React, {useEffect, useState} from 'react';


interface LoanCalculatorProps {
  upfrontPayment: number;
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
  loanBidrag: number;
  maxLoanTerm: number;
  headerName: string;
  onValueChange: (data: LoanData[], loan: string) => void;
}

interface LoanData {
    Year: number;
    InterestPaid: number;
    PrincipalPaid: number;
    TotalPaid: number;
    TotalPaidOffLoan: number;
    MonthlyPayment: number;
    UpfrontPayment: number;
  }

const LoanTable: React.FC<LoanCalculatorProps> = ({
  upfrontPayment,
  loanAmount,
  interestRate,
  loanTerm,
  loanBidrag,
  maxLoanTerm,
  headerName,
  onValueChange,
}) => {

  const [loanData, setLoanData] = useState<LoanData[]>([]);
  let monthlyInterestRate: number;
  const calculateLoan = (): LoanData[] => {
    if (headerName == 'Realkreditlån') {
      monthlyInterestRate = (interestRate + loanBidrag) / 100 / 12; // Include bidragssats
    } else {
      monthlyInterestRate = interestRate / 100 / 12;
    }
    const numberOfPayments = loanTerm * 12;

    const principalAmount = loanAmount * 1.03;
    const monthlyPayment =
      principalAmount *
      monthlyInterestRate /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
  
    let remainingLoanAmount = principalAmount;
    let totalPaid = 0;
    let totalPaidOffLoan = 0;
    const newData: LoanData[] = [];

    for (let year = 1; year <= maxLoanTerm; year++) {
      if (year > loanTerm) {
        newData.push({
            Year: year,
            InterestPaid: 0,
            PrincipalPaid: 0,
            TotalPaid: totalPaid,
            TotalPaidOffLoan: totalPaidOffLoan,
            MonthlyPayment: monthlyPayment,
            UpfrontPayment: upfrontPayment,
          });        
        continue
      }
      let totalInterestPaid = 0;
      let totalPrincipalPaid = 0;
      for (let month = 1; month <= 12; month++) {
        const interestPayment = remainingLoanAmount * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
        remainingLoanAmount -= principalPayment;
        totalInterestPaid += interestPayment;
        totalPrincipalPaid += principalPayment;
      }
      totalPaid += totalInterestPaid + totalPrincipalPaid;
      totalPaidOffLoan += totalPrincipalPaid;

      if (totalPaidOffLoan >= loanAmount) {
        totalPaidOffLoan = loanAmount;
      } 

      newData.push({
        Year: year,
        InterestPaid: totalInterestPaid,
        PrincipalPaid: totalPrincipalPaid,
        TotalPaid: totalPaid,
        TotalPaidOffLoan: totalPaidOffLoan,
        MonthlyPayment: monthlyPayment,
        UpfrontPayment: upfrontPayment,
      });
    }
  
    return newData;
  };

    // Call the onValueChange function with the value to pass it to the parent component
  useEffect(() => {
    const loanData = calculateLoan();
    setLoanData(loanData);
    onValueChange(loanData, headerName);
  }, [upfrontPayment, loanAmount, interestRate, loanTerm, loanBidrag, maxLoanTerm]);

  const formatNumber = (num: number): string => {
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div>
      <h2>{headerName}</h2>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Renter betalt</th>
            <th>Afbetalt af lån</th>
          </tr>
        </thead>
        <tbody>
          {loanData.map((data, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{formatNumber(data.InterestPaid)}</td>
              <td>{formatNumber(data.TotalPaidOffLoan)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoanTable;
