import React, { useState, useEffect } from 'react';


interface LoanData2 {
    Year: number;
    InterestPaid: number;
    PrincipalPaid: number;
    TotalPaid: number;
    TotalPaidOffLoan: number;
    MonthlyPayment: number;
    UpfrontPayment: number;
}

interface TotalTableProps {
    loanData1: LoanData2[];
    loanData2: LoanData2[];
    upFrontPayment: number;
    interestOnSavings: number;
    huslejeOpsparing: number;
    loan1Length: number;
    loan2Length: number;
    savingsData: number[];
}

const TotalTable: React.FC<TotalTableProps> = ({
    loanData1,
    loanData2,
    upFrontPayment,
    interestOnSavings,
    huslejeOpsparing,
    loan1Length,
    loan2Length,
    savingsData
}) => {
    const [interest, setInterest] = useState<number[]>([]);
    const [savings, setSavings] = useState<number[]>([]);

    useEffect(() => {
        const calculateInterest = () => {
            const newInterest: number[] = [];

            for (let i = 0; i < Math.max(loan1Length, loan2Length); i++) {
                const totalInterest = (loanData1[i].InterestPaid) + (loanData2[i].InterestPaid);
                let renteFradrag = 0;

                if (totalInterest > 100000) {
                    renteFradrag = totalInterest * 20.6 / 100;
                } else {
                    renteFradrag = totalInterest * 33.6 / 100;
                }

                newInterest.push(renteFradrag);
            }

            return newInterest;
        };
        
        const calculateSavings = (): number[] => {
            let savingsBalance = 0;
            const savingsData: number[] = [];
        
            for (let year = 1; year <= Math.max(loan1Length, loan2Length); year++) {
              let monthSavingsBalance = savingsBalance;
              let monthlyPaymentToSave = huslejeOpsparing;
              
              if (year <= loan1Length && loanData1[0].MonthlyPayment) {
                monthlyPaymentToSave -= loanData1[0].MonthlyPayment
              }
              if (year <= loan2Length && loanData2[0].MonthlyPayment) {
                monthlyPaymentToSave -= loanData2[0].MonthlyPayment
              }

              for (let i = 1; i <= 12; i++) {
                const rentEarned = monthSavingsBalance * (interestOnSavings / 100 / 12);
                monthSavingsBalance += monthlyPaymentToSave + rentEarned;
              }
              savingsBalance = monthSavingsBalance;
              savingsData.push(savingsBalance);
            }
        
            return savingsData;
          };

        setInterest(calculateInterest());
        setSavings(calculateSavings());
    }, [loanData1, loanData2, upFrontPayment, interestOnSavings, huslejeOpsparing, loan1Length, loan2Length, savingsData]);

    const formatNumber = (num: number): string => {
        return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    return (
        <div>
            <h2>Samlet overblik over økonomi ved lån</h2>
            <table className="custom-table2">
                <thead>
                    <tr>
                        <th>Rentefradrag (værdi)</th>
                        <th>Opsparing med lån</th>
                        <th>Total beløb i ejendom</th>
                        <th>Total beløb ved køb</th>
                        <th>Gevinst ved køb</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Assuming loanData1 and loanData2 have the same length */}
                    {loanData1.map((data, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                            <td>{formatNumber(interest[index] ?? 0)}</td>
                            <td>{formatNumber(savings[index] ?? 0)}</td>
                            <td>{formatNumber((data.TotalPaidOffLoan + (loanData2[index]?.TotalPaidOffLoan ?? 0) + upFrontPayment))}</td>
                            <td>{formatNumber((data.TotalPaidOffLoan + (loanData2[index]?.TotalPaidOffLoan ?? 0) + upFrontPayment) + interest[index] + savings[index])}</td>
                            <td>{formatNumber((data.TotalPaidOffLoan + (loanData2[index]?.TotalPaidOffLoan ?? 0) + upFrontPayment) + interest[index] + savings[index] - savingsData[index])}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TotalTable;
