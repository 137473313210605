import React, { useEffect, useState } from 'react';


interface SavingsCalculatorProps {
  upfrontPayment: number;
  monthlyPaymentToSave: number;
  interestOnSaveUp: number;
  loanTerm: number;
  onValueChange: (data: number[]) => void;
}

// Move calculateSavings outside of the component
const calculateSavings = (
  upfrontPayment: number,
  monthlyPaymentToSave: number,
  interestOnSaveUp: number,
  loanTerm: number
): number[] => {
  let savingsBalance = upfrontPayment;
  const savingsData: number[] = [];
  for (let year = 1; year <= loanTerm; year++) {
    let monthSavingsBalance = savingsBalance;

    for (let i = 1; i <= 12; i++) {
      const rentEarned = monthSavingsBalance * (interestOnSaveUp / 100 / 12);
      monthSavingsBalance += monthlyPaymentToSave + rentEarned;

    }
    savingsBalance = monthSavingsBalance;
    savingsData.push(savingsBalance);
  }

  return savingsData;
};

const SavingsTable: React.FC<SavingsCalculatorProps> = ({
  upfrontPayment,
  monthlyPaymentToSave,
  interestOnSaveUp,
  loanTerm,
  onValueChange
}) => {
  const [savingsData, setSavingsData] = useState<number[]>([]);

  useEffect(() => {
    const savingsData = calculateSavings(upfrontPayment, monthlyPaymentToSave, interestOnSaveUp, loanTerm);
    setSavingsData(savingsData);
    onValueChange(savingsData);
  }, [upfrontPayment, monthlyPaymentToSave, interestOnSaveUp, loanTerm]);

  const formatNumber = (num: number): string => {
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div>
      <h2>Opsparing (uden lån)</h2>
      <table className="custom-table">
        <thead>
          <tr>
            <th>År</th>
            <th>Opsparing</th>
          </tr>
        </thead>
        <tbody>
          {savingsData.map((balance, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{index + 1}</td>
              <td>{formatNumber(balance)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SavingsTable;
