import React, { useState } from 'react';
import './ChatbotPopup.css'; // You can style this component further
import image1 from '../botpic.png';
import Chat from './Chat'

const ChatbotPopup: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const closeChat = () => {
    setIsOpen(false);
  };

  return (
    <div className="chatbot-popup">
      {!isOpen && (
        <div className="chatbot-logo" onClick={toggleChat}>
          {/* Place your logo image here */}
          <img src={image1} alt="Chatbot Logo" className='chatbot-image'/>
        </div>
      )}
      {isOpen && (
        <div className="chatbot-interface">
          <div className="chat-header">
          <span>Chat med Mr. (chat)robot</span>
          <button className="close-button" onClick={closeChat}>
          Luk
            </button>
            </div>

<Chat/>
            
        

        </div>
      )}
    </div>
  );
};

export default ChatbotPopup;
