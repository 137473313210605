import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import { ReactComponent as Brand } from '../logo.svg'
import './Navbar.css'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const closeBurgerMenu = () => {
    setShowNavbar(!showNavbar)
    setOpen(false)
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Brand />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}` }>
          <ul>
            <li onClick={closeBurgerMenu}>
              <NavLink to="/">Home</NavLink>
            </li>
            <li onClick={closeBurgerMenu}>
              <NavLink to="/speciale">Speciale</NavLink>
            </li>
            <li onClick={closeBurgerMenu}>
              <NavLink to="/låneberegner">Hobbyprojekt: Låneberegner</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar