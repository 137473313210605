import React, { useState, useCallback } from 'react';
import './LåneBeregner.css';
import LåneBeregnerInputs from '../components/LåneBeregnerInputs';
import LåneBeregnerOpsparing from '../components/LåneBeregnerOpsparing';
import LåneBeregnerOverblik from '../components/LåneBeregnerOverblik';
import LåneBeregnerLån from '../components/LåneberegnerLån';
import LåneBeregnerTotal from '../components/LåneBeregnerTotal';

interface InputFromLoanCalculator {
  totalLoanAmount: number;
  upfrontPayment: number;
  personsNumber: number;
  rentEjerudgifter: number;
  rentCurrent: number;
  monthlyAmountToSavings: number;
  interestOnSavings: number;
  interestLoan1: number;
  loanTerm1: number;
  interestLoan2: number;
  loanTerm2: number;
  loanAmount1: number;
  loanAmount2: number;
  loanBidrag: number;
}
interface LoanData {
  Year: number;
  InterestPaid: number;
  PrincipalPaid: number;
  TotalPaid: number;
  TotalPaidOffLoan: number;
  MonthlyPayment: number;
  UpfrontPayment: number;
}
const LåneBeregner: React.FC = () => {
  // State to hold the input values
  const [inputValues, setInputValues] = useState<InputFromLoanCalculator | null>();
  const [savingsData, setSavingsData] = useState<number[] | null>(null);
  const [loanData1, setLoanData1] = useState<LoanData[] | null>(null);
  const [loanData2, setLoanData2] = useState<LoanData[] | null>(null);





  // Function to handle receiving the value from SavingsTable
  const handleInputData = useCallback((data: InputFromLoanCalculator) => {
    setInputValues(data);
}, [setInputValues]);

  // Function to handle receiving the value from LoanTable
  const handleLoanTableValue = useCallback((data: LoanData[], loan: string) => {
      setLoanData1(data);

  }, [setLoanData1]);
    // Function to handle receiving the value from LoanTable
    const handleLoanTableValue2 = useCallback((data: LoanData[], loan: string) => {
        setLoanData2(data);
    }, [setLoanData2]);

    const handleSavingsTableValue = useCallback((data: number[]) => {
      setSavingsData(data);
  }, [setSavingsData]);


  return (
    <div>
      <div className='top-row'>
      <LåneBeregnerInputs onInputChange={handleInputData} />
      {loanData1 && loanData2 && inputValues && <LåneBeregnerOverblik
          monthlyPaymentLoan1={loanData1[0].MonthlyPayment}
          monthlyPaymentLoan2={loanData2[0].MonthlyPayment}
          fullLoanAmount1={loanData1[loanData1.length - 1].TotalPaid}
          fullLoanAmount2={loanData2[loanData2.length - 1].TotalPaid}
          ejerudgifter={inputValues.rentEjerudgifter}
          minLoanTerm={Math.min(inputValues.loanTerm1, inputValues.loanTerm2)}

      />
      }
      </div>
      <div className='bottom-row'>
      {inputValues && <LåneBeregnerOpsparing
        upfrontPayment={inputValues.upfrontPayment}
        monthlyPaymentToSave={inputValues.monthlyAmountToSavings}
        interestOnSaveUp={inputValues.interestOnSavings}
        loanTerm={Math.max(inputValues.loanTerm1, inputValues.loanTerm2)}
        onValueChange={handleSavingsTableValue}
      />
      }
      {inputValues && <LåneBeregnerLån
          upfrontPayment={inputValues.upfrontPayment}
          loanAmount={inputValues.loanAmount1}
          interestRate={inputValues.interestLoan1}
          loanTerm={inputValues.loanTerm1}
          loanBidrag={inputValues.loanBidrag}
          maxLoanTerm={Math.max(inputValues.loanTerm1, inputValues.loanTerm2)}
          headerName="Realkreditlån"
          onValueChange={handleLoanTableValue}
      />
      }
      {inputValues && <LåneBeregnerLån
          upfrontPayment={inputValues.upfrontPayment}
          loanAmount={inputValues.loanAmount2}
          interestRate={inputValues.interestLoan2}
          loanTerm={inputValues.loanTerm2}
          loanBidrag={inputValues.loanBidrag}
          maxLoanTerm={Math.max(inputValues.loanTerm1, inputValues.loanTerm2)}
          headerName="Banklån"
          onValueChange={handleLoanTableValue2}
      />
      } 
      {inputValues && savingsData && loanData1 && loanData2 && <LåneBeregnerTotal
          loanData1={loanData1}
          loanData2={loanData2}
          upFrontPayment={inputValues.upfrontPayment}
          interestOnSavings={inputValues.interestOnSavings}
          huslejeOpsparing={inputValues.rentCurrent + inputValues.monthlyAmountToSavings - inputValues.rentEjerudgifter}
          loan1Length={inputValues.loanTerm1}
          loan2Length={inputValues.loanTerm2}
          savingsData={savingsData}
      />
      }
    </div>
    </div>
  );
};

export default LåneBeregner;
