import React from 'react';

interface LoanOverviewProps {
  monthlyPaymentLoan1: number;
  monthlyPaymentLoan2: number;
  fullLoanAmount1: number;
  fullLoanAmount2: number;
  ejerudgifter: number;
  minLoanTerm: number;
}

const formatNumber = (num: number): string => {
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

const LoanOverview: React.FC<LoanOverviewProps> = ({ monthlyPaymentLoan1, monthlyPaymentLoan2, fullLoanAmount1, fullLoanAmount2, ejerudgifter, minLoanTerm}) => {
  return (
    <div className="loan-overview">
      <h2>Overblik</h2>
      <div className="columns">
        <div className="column">
          <p>Månedlig betaling (Realkreditlån): <span className="numbers">{formatNumber(monthlyPaymentLoan1)}</span></p>
          <p>Beløb til tilbagebetaling (Realkreditlån): <span className="numbers">{formatNumber(fullLoanAmount1)}</span></p>
        </div>
        <div className="column">
          <p>Månedlig betaling (Banklån): <span className="numbers">{formatNumber(monthlyPaymentLoan2)}</span></p>
          <p>Beløb til tilbagebetaling (Banklån): <span className="numbers">{formatNumber(fullLoanAmount2)}</span></p>
        </div>
      </div>
      <p className="big-number">Samlet husleje de første {String(minLoanTerm)} år: <span className="numbers">{formatNumber(ejerudgifter + monthlyPaymentLoan1 + monthlyPaymentLoan2)}</span></p>
    </div>
  );
};

export default LoanOverview;
