import React, { useState, useEffect, useReducer } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageModel,
  TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import { Pinecone } from "@pinecone-database/pinecone";
import { OpenAIEmbeddings, ChatOpenAI } from "@langchain/openai";
import { ChatPromptTemplate, MessagesPlaceholder } from "@langchain/core/prompts";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { createStuffDocumentsChain } from "langchain/chains/combine_documents";
import { Document } from "@langchain/core/documents";
import { AIMessage, HumanMessage } from "@langchain/core/messages";
import { PromptTemplate } from "@langchain/core/prompts";
import './ChatbotPopup';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const PINECONE_API_KEY = process.env.REACT_APP_PINECONE_API_KEY || "";

const pc = new Pinecone({
  apiKey: PINECONE_API_KEY
});
const indexName = "rag5";
const index = pc.Index(indexName);

const ChatbotPopup: React.FC = () => {
  // State to store chat messages
  const [isChatbotTyping, setIsChatbotTyping] = useState(false);
  const [chatHistory, setChatHistory] = useState<(HumanMessage | AIMessage)[]>([])
  const [chatMessages, setChatMessages] = useState<MessageModel[]>([
    {
      message: 'Hej og velkommen til min CV side. Stil min chatbot nogle spørgsmål',
      sender: 'ChatGPT',
      direction: 0,
      position: 0,
    },
  ]);

  const contextualizeQuestion = async (llm: ChatOpenAI, userMessage: string) => {
    const contextualizeQSystemPrompt = `Given a chat history and the latest user question
    which might reference context in the chat history, formulate a standalone question
    which can be understood without the chat history. Do NOT answer the question,
    just reformulate it if needed and otherwise return it as is.
    Ask the question in Danish`;

    /*Du får en chat historik og et spørgsmål fra en bruger. 
    Hvis spørgsmålet forståes via konteksten fra chat historikken, så skal du omformulere spørgsmålet, så det kan stå alene uden historikken.
    Ellers skal du bare returnere præcis det samme spørgsmål.
    Du skal IKKE forsøge at svare på spørgsmålet.*/
    
    const contextualizeQPrompt = ChatPromptTemplate.fromMessages([
      ["system", contextualizeQSystemPrompt],
      new MessagesPlaceholder("chat_history"),
      ["human", "{question}"],
    ]);
    const contextualizeQChain = contextualizeQPrompt
      .pipe(llm)
      .pipe(new StringOutputParser());

      const res = await contextualizeQChain.invoke({
        chat_history: chatHistory.slice(-4),
        question: userMessage,
      });
  return res
  }

  const firstPrompt = async (llm: ChatOpenAI, userMessage: string) => {
    const contextualizeQSystemPrompt = `Given a chat history and the latest user message, 
    reply with either "question", "greeting" or "end_of_current_conversation" based on what the user is trying to get. 
    A greeting is something like "hej med dig", "hej", or similar.
    A question is every time the user is asking for some information like "Ja, hvad laver du til daglig?" or "Nævn nogle ting, som du er stolt af".
    A end_of_conversation is when the user responds with something like "Fedt" or "Ok", where the user does not want more information.
    Do NOT reply with anything other than "question", "greeting" or "end_of_current_conversation".`;

    /*Du får en chat historik og et spørgsmål fra en bruger. 
    Hvis spørgsmålet forståes via konteksten fra chat historikken, så skal du omformulere spørgsmålet, så det kan stå alene uden historikken.
    Ellers skal du bare returnere præcis det samme spørgsmål.
    Du skal IKKE forsøge at svare på spørgsmålet.*/
    
    const contextualizeQPrompt = ChatPromptTemplate.fromMessages([
      ["system", contextualizeQSystemPrompt],
      new MessagesPlaceholder("chat_history"),
      ["human", "{question}"],
    ]);
    const contextualizeQChain = contextualizeQPrompt
      .pipe(llm)
      .pipe(new StringOutputParser());

      const res = await contextualizeQChain.invoke({
        chat_history: chatHistory.slice(-4),
        question: userMessage,
      });
  return res
  }


  // Function to handle user messages
  const handleUserMessage = async (userMessage: string) => {
    // Create a new user message object
    const newUserMessage: MessageModel = {
      message: userMessage,
      sender: 'user',
      direction: 1,
      position: 0,
    };


    // Update chat messages state with the new user message
    const updatedChatMessages = [...chatMessages, newUserMessage];
    setChatMessages(updatedChatMessages);
    setIsChatbotTyping(true);
    if( isMobile.iOS() ) {
      const iphoneMessage: MessageModel = {
        message: 'Jeg kan se, at du skriver fra en Iphone, og Iphones virker pt ikke. Hop på computeren og chat med mig :)',
        sender: 'ChatGPT',
        direction: 0,
        position: 0,
      };
      chatMessages.push(newUserMessage)
      const updatedChatMessages = [...chatMessages, iphoneMessage];
      setChatMessages(updatedChatMessages);
      setIsChatbotTyping(false);
    }
    else {
      await processUserMessageToChatGPT(updatedChatMessages, userMessage);
    }
  };

  async function processUserMessageToChatGPT(messages: MessageModel[], userMessage: string) {
    // Prepare the messages in the required format for the API
    const embeddings = new OpenAIEmbeddings({
      openAIApiKey: OPENAI_API_KEY, // In Node.js defaults to process.env.OPENAI_API_KEY
      batchSize: 512, // Default value if omitted is 512. Max is 2048
      modelName: "text-embedding-3-large",
  });
  const llm = new ChatOpenAI({ modelName: "gpt-3.5-turbo", temperature: 0, openAIApiKey: OPENAI_API_KEY });

  let initial_conversation = await firstPrompt(llm, userMessage)

  if (initial_conversation == "greeting") {
    setChatMessages([...messages, {
      message: "Hej med dig :) Har du nogle spørgsmål?",
      sender: 'ChatGPT',
      direction: 0,
      position: 0,
    },])
    setIsChatbotTyping(false);
  } else if (initial_conversation == "end_of_current_conversation") {
    setChatMessages([...messages, {
      message: "Har du flere spørgsmål?",
      sender: 'ChatGPT',
      direction: 0,
      position: 0,
    },])
    setIsChatbotTyping(false);

  } else {
    let question = await contextualizeQuestion(llm, userMessage)

    const queryEmbedding = await embeddings.embedQuery(question);
    const queryResponse = await index.query({
      vector: queryEmbedding,
      topK: 3,
      includeValues: true,
      includeMetadata: true,
    });
    if (queryResponse.matches.length) {

    const documentList: Document[] = queryResponse.matches.map((value: any) => {
      return new Document({ pageContent: value.metadata.pageContent });
    });
    const template = `Du er min (Nicklas) assistent, som fungerer som en chatbot og svarer på spørgsmål ved et job interview.
    Du skal svare i 3. person, hvor du skriver Nicklas i stedet for jeg.
    Du skal svare på spørgsmålet til sidst UDEN at opdigte viden og svare klart og tydeligt på maks 3 sætninger. 
    Brug den følgende kontekst til at svare på spørgsmålet. 
    Hvis du ikke kan svare på spørgsmålet, skal du beklage og sige at de kan invitere mig til en samtale og få svar på det.
    Jeg gentager, du må IKKE opdigte noget eller nævne svagheder om mig, hvis du ikke kan svare, skal du bare beklage og sige, at de kan invitere til en samtale og få svar på spørgsmålet.
    Hvis vedkommende spørger til en samtale, skal du takke for invitationen og henvise til mine kontaktoplysninger: 22319394 eller nicklasc93@gmail.com.

    Context: {context}

    Question: {question}`;

    /*const template = `You are my assistent helping me out in a job interview situation, answering the question about me given the following context.
      Answer in Danish, use Nicklas instead of I, do NOT make anything up and answer in a maximum of 3 sentences.
      If you can not answer the question based on the context, simply reply by letting them know that they can invite me for an interview in person.    Brug den følgende kontekst til at svare på spørgsmålet. 
      If the user invites me for an interview, thank the user and give him my contact details: 22319394 eller nicklasc93@gmail.com.
      If there conversation is marked as ended, reply with "Har du flere spørgsmål?".

    {context}

    Question: {question}

    Helpful Answer:`*/

    const customRagPrompt = PromptTemplate.fromTemplate(template);
    const ragChain = await createStuffDocumentsChain({
      llm,
      prompt: customRagPrompt,
      outputParser: new StringOutputParser(),
    });

    const res = await ragChain.invoke({
      question: question,
      context: documentList
    });
    setChatHistory([...chatHistory, new HumanMessage(question), new AIMessage(res)])

    setChatMessages([...messages, {
      message: res,
      sender: 'ChatGPT',
      direction: 0,
      position: 0,
    },]);
    } else {
      setChatMessages([...messages, {
        message: "Der er et problem med api-adgangen. Prøv igen senere.",
        sender: 'ChatGPT',
        direction: 0,
        position: 0,
      }])
    }
    setIsChatbotTyping(false);
    }
  }


  const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};


  return (
    <>
      {/* A container for the chat window */}
      <div className='chatContainer'>
        {/* All components are wrapped in the MainContainer */}
        <MainContainer>
          {/* All chat logic will be contained in the ChatContainer */}
          <ChatContainer>
            {/* Shows all our messages */}
            <MessageList
            typingIndicator={
              isChatbotTyping ? (
                <TypingIndicator content="Mr. (chat)robot tænker lige..." />
              ) : null
            }
            >
              {/* Map through chat messages and render each message */}
              {chatMessages.map((message, i) => (
                <Message
                  key={i}
                  model={{
                    message: message.message,
                    sender: message.sender,
                    direction: message.direction,
                    position: message.position,
                  }}
                  style={message.sender === 'ChatGPT' ? { textAlign: 'left' } : {}}
                />
              ))}
            </MessageList>
            <MessageInput placeholder='Skriv dit spørgsmål her...' onSend={handleUserMessage} />
          </ChatContainer>
        </MainContainer>
      </div>
    </>
  );
};

export default ChatbotPopup;
