import React, { useState, useCallback, useEffect } from 'react';

interface InputFromLoanCalculator {
    totalLoanAmount: number;
    upfrontPayment: number;
    personsNumber: number;
    rentEjerudgifter: number;
    rentCurrent: number;
    monthlyAmountToSavings: number;
    interestOnSavings: number;
    interestLoan1: number;
    loanTerm1: number;
    interestLoan2: number;
    loanTerm2: number;
    loanAmount1: number;
    loanAmount2: number;
    loanBidrag: number;
  }

  interface LåneBeregnerInputsProps {
    onInputChange: (inputData: any) => void;
  }

const LoanCalculator: React.FC<LåneBeregnerInputsProps> = ({ onInputChange }) => {
  const [loanAmount, setLoanAmount] = useState<string>('3000000');
  const [persons, setPersons] = useState<string>('2');
  const [ejerudgifter, setEjerudgifter] = useState<string>('4500');
  const [interestRate, setInterestRate] = useState<string>('4');
  const [loanTerm, setLoanTerm] = useState<string>('30');
  const [interestRate2, setInterestRate2] = useState<string>('7');
  const [loanTerm2, setLoanTerm2] = useState<string>('10');
  const [upfrontPayment, setUpfrontPayment] = useState<string>('300000');
  const [rent, setRent] = useState<string>('10000');
  const [monthlyPaymentToSave, setMonthlyPaymentToSave] = useState<string>('15000');
  const [interestOnSaveUp, setInterestOnSaveUp] = useState<string>('2');
  const [loanBidrag, setLoanBidrag] = useState<string>('0.75');


  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
    const isDigit = /[0-9]/.test(e.key);
    const isDotOrComma = /[.,]/.test(e.key);
    const inputValue = e.currentTarget.value;
    const dotIndex = inputValue.indexOf('.');
    const commaIndex = inputValue.indexOf(',');

    if (!allowedKeys.includes(e.key) && !isDigit && !isDotOrComma) {
        e.preventDefault();
        return;
      }
  
    if ((dotIndex !== -1 || commaIndex !== -1) && (isDotOrComma && e.currentTarget.selectionStart! > Math.max(dotIndex, commaIndex))) {
        e.preventDefault();
        return;
      }
  
    if ((dotIndex !== -1 || commaIndex !== -1) && isDigit && e.currentTarget.selectionStart! > Math.max(dotIndex, commaIndex)) {
        const decimalPart = inputValue.substring(dotIndex !== -1 ? dotIndex + 1 : commaIndex + 1);
    if (decimalPart.length >= 2) {
          e.preventDefault();
          return;
        }
      };
  };

  const calculateLoan = () => {
    try {
        // Set loanAmount according to upfrontPayment and whether it is bankloan/realkreditlån
        let newLoanAmount1 = 0;
        let newLoanAmount2 = 0;
        
        if (Number(loanAmount) - Number(loanAmount) * 0.8 > Number(upfrontPayment)) {
            newLoanAmount1 = Number(loanAmount) * 0.8;
            newLoanAmount2 = Number(loanAmount) * 0.2 - Number(upfrontPayment);
        } else {
            newLoanAmount1 = Number(loanAmount) - Number(upfrontPayment);
            newLoanAmount2 = 0;
        }
        
        const data: InputFromLoanCalculator = {
            totalLoanAmount: parseFloat(loanAmount) || 0,
            upfrontPayment: parseFloat(upfrontPayment) || 0,
            personsNumber: parseFloat(persons) || 0,
            rentEjerudgifter: parseFloat(ejerudgifter) || 0,
            rentCurrent: parseFloat(rent) || 0,
            monthlyAmountToSavings: parseFloat(monthlyPaymentToSave) || 0,
            interestOnSavings: parseFloat(interestOnSaveUp) || 0,
            interestLoan1: parseFloat(interestRate) || 0,
            loanTerm1: parseFloat(loanTerm) || 0,
            interestLoan2: parseFloat(interestRate2) || 0,
            loanTerm2: parseFloat(loanTerm2) || 0,
            loanAmount1: newLoanAmount1 || 0,
            loanAmount2: newLoanAmount2 || 0,
            loanBidrag: parseFloat(loanBidrag) || 0,
        };
        return data;
    } catch (error) {
        return null;
    }
};



const handleCalculate = () => {
    const calculatedData = calculateLoan();
    onInputChange(calculatedData);
};

  return (
    <div>
      <div className="loan-calculator-container">
        <h1>Låne Beregner til køb af Ejerlejlighed</h1>
        <h2>Hvor meget skal du låne og hvad er din udbetaling/opsparing?</h2>

        <div className="input-group-container">

        <div className="input-group">
          <label htmlFor="loanAmount">Total lånebeløb:</label>
          <input
            id="loanAmount"
            type="text"
            value={loanAmount}
            onKeyDown={handleKeyDown}
            onChange={(e) => setLoanAmount(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="upfrontPayment">Udbetaling/opsparing:</label>
          <input
            id="upfrontPayment"
            type="text"
            value={upfrontPayment}
            onKeyDown={handleKeyDown}
            onChange={(e) => setUpfrontPayment(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="perons">Personer til lånet:</label>
          <input
            id="persons"
            type="text"
            value={persons}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPersons(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="ejerudgifter">Ejerudgifter:</label>
          <input
            id="ejerudgifter"
            type="text"
            value={ejerudgifter}
            onKeyDown={handleKeyDown}
            onChange={(e) => setEjerudgifter(e.target.value)}
          />
        </div>
        <h2>Hvad er din nuværende husleje, hvor meget sender du til opsparing og hvad er renten på din opsparing?</h2>
        <div className="input-group">
          <label htmlFor="rent">Nuværende husleje:</label>
          <input
            id="rent"
            type="text"
            value={rent}
            onChange={(e) => setRent(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="monthlyPaymentToSave">Nuværende månedlige beløb til opsparing:</label>
          <input
            id="monthlyPaymentToSave"
            type="text"
            value={monthlyPaymentToSave}
            onChange={(e) => setMonthlyPaymentToSave(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="rentOnSaveUp">Renter på opsparing (%):</label>
          <input
            id="rentOnSaveUp"
            type="text"
            value={interestOnSaveUp}
            onChange={(e) => setInterestOnSaveUp(e.target.value)}
          />
        </div>
        </div>
        <h2>Hvad er renten og løbetiden på realkreditlånet?</h2>
        <div className="input-group-container">
        <div className="input-group">
          <label htmlFor="interestRate">Rente (Realkreditlån) (%):</label>
          <input
            id="interestRate"
            type="text"
            value={interestRate}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="loanBidrag">Bidragssats (Realkreditlån) (%):</label>
          <input
            id="loanBidrag"
            type="text"
            value={loanBidrag}
            onKeyDown={handleKeyDown}
            onChange={(e) => setLoanBidrag(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="loanTerm">Løbetid (Realkreditlån) (år):</label>
          <input
            id="loanTerm"
            type="text"
            value={loanTerm}
            onKeyDown={handleKeyDown}
            onChange={(e) => setLoanTerm(e.target.value)}
          />
        </div>
        </div>
        <h2>Hvad er renten og løbetiden på banklånet?</h2>
        <div className="input-group-container">

        <div className="input-group">
          <label htmlFor="interestRate2">Renter (BankLån) (%):</label>
          <input
            id="interestRate2"
            type="text"
            value={interestRate2}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInterestRate2(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="loanTerm2">Løbetid (BankLån) (år):</label>
          <input
            id="loanTerm2"
            type="text"
            value={loanTerm2}
            onKeyDown={handleKeyDown}
            onChange={(e) => setLoanTerm2(e.target.value)}
          />
        </div>
        </div>
        <button onClick={handleCalculate}>Calculate</button>      


      </div>
    </div>
    
  );
};

export default LoanCalculator;


