// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Resume from './pages/Resume';
import SpecialePage from './pages/SpecialePage';
import LåneBeregner from './pages/LåneBeregner';
import ChatbotPopup from './components/ChatbotPopup';

const data = [
  { column1: 'Row 1 Data 1', column2: 'Row 1 Data 2', column3: 'Row 1 Data 3' },
  { column1: 'Row 2 Data 1', column2: 'Row 2 Data 2', column3: 'Row 2 Data 3' },
  { column1: 'Row 3 Data 1', column2: 'Row 3 Data 2', column3: 'Row 3 Data 3' },
  { column1: 'Row 4 Data 1', column2: 'Row 4 Data 2', column3: 'Row 4 Data 3' },
  // Add more rows as needed
];

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Resume />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/speciale" element={<SpecialePage />} />
          <Route path="/låneberegner" element={<LåneBeregner />} />
        </Routes>
      </div>
      <ChatbotPopup/>
    </Router>

  );
};

export default App;
