// src/components/Resume.tsx
import React from 'react';
import './Resume.css'; // Import the CSS file
import image1 from "../profilepic.jpg";
import ChatbotPopup from '../components/ChatbotPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobeAmericas, faExclamation, faMapMarkerAlt, faUserGraduate, faBriefcase, faPhone
} from '@fortawesome/free-solid-svg-icons';

import {
  faEnvelope
} from '@fortawesome/free-regular-svg-icons';



interface ResumeItem {
  title: string;
  place: string;
  date: string;
  description: string;
}

interface StudiesItem {
  subject: string;
  school: string;
  level: string;
  date: string;
}

const resumeData: ResumeItem[] = [
  {
    title: 'Data Scientist',
    place: 'Forsvarsministeriet',
    date: 'October 2023 - Present',
    description: 'General Data Science tasks; Work with LLMs, visualizations in dash-apps, developing machine learning models.\n Structuring, analysing and explaining trends from data for stakeholders'
  },
  {
    title: 'Junior Data Scientist',
    place: 'Brøndby IF',
    date: 'September 2020 - June 2023',
    description: 'Developing machine learning models to evaluate players in new ways. Visualizing data for easy access for scouts/coaches'
  },
  {
    title: 'Student Worker',
    place: 'Styrelsen for International Rekruttering og Integration',
    date: 'September 2020 - September 2021',
    description: 'Automation of workflows using RPA-tools like UIPath'
  },
  {
    title: 'Student Worker',
    place: 'Københavns Politi',
    date: 'September 2016 - April 2019',
    description: 'Administrative work in the economics department'
  },
  // Add more items as needed
];

const studiesData: StudiesItem[] = [
  {
    school: 'Københavns Universitet',
    subject: 'Computer Science (Data Science)',
    level:  'Masters Degree',
    date: '2021 - 2023',
  },
  {
    school: 'Københavns Universitet',
    subject: 'Computer Science (Data Science)',
    level:  'Bachelors Degree',
    date: '2018 - 2021',
  },
  {
    school: 'Roskilde Universitet',
    subject: 'Business Administration',
    level:  'Bachelors Degree',
    date: '2014 - 2017',
  },

  // Add more items as needed
];

interface ProfileText {
  name: string;

}

const Resume: React.FC = () => {
  return (
    <div className="resume-container">
      <div className="profile-edu-container">
      <div className="profile-section">
        <div className="profile-image-wrapper">
          <img src={image1} alt="Your Name" className="profile-image" />
            <h1>{"Contact"}</h1>
            <div style={{ display: 'flex', alignItems: 'center', gap:'10px' }}>
            <FontAwesomeIcon icon={faEnvelope} />

            <p>{"nicklasc93@gmail.com"}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap:'5px' }}>
            <FontAwesomeIcon icon={faPhone} />

            <p>{"+45 22 31 93 94"}</p>
            </div>
            </div>

        </div> 

        <div className="experience-items">
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faUserGraduate} size='2x'/>
      <h1 style={{ marginLeft: '0.5rem' }}>Education</h1>
    </div>
        {studiesData.map((item, index) => (
          <div className="experience-item" key={index}>
            <h2>{item.school}: {item.date}</h2>
            <p>{item.subject}</p>
            <p>{item.level}</p>
          </div>
        ))}
                </div>

      </div>
      <div className="experience-items">
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faBriefcase} size='2x'/>
      <h1 style={{ marginLeft: '0.5rem' }}>Experience</h1>
    </div>
        {resumeData.map((item, index) => (
          <div className="experience-item" key={index}>
            <h2>{item.title}</h2>
            <h3>{item.place}</h3>
            <p>{item.date}</p>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resume;
