// src/components/Resume.tsx
import React from 'react';
import './SpecialePage.css'; // Import the CSS file
import SpecialeOverview from "../speciale_overview.png";


const Resume: React.FC = () => {
  return (
    <div >
        <img src={SpecialeOverview} alt="Speciale Overview" className="speciale-image" />
        <div className="speciale-text">
        <p>Mit speciale <b>”Automatic recommendation of players in the Danish Superliga using Twitter posts and simple player data”</b> blev skrevet med Brøndby IF.</p> 
        <p>Jeg ville gerne arbejde med <b>tekst og NLP</b>, så specialets mål blev at undersøge, om man kan bruge fans’ holdninger (tweets) til de forskellige spillere i rekrutteringsfasen. </p> 
        <p>Jeg <b>scrapede derfor først Twitter</b> for relevante tweets. Undersøgelsen blev begrænset til Superligaen fra 2019-2022, hvilket gav <b>500.000 tweets</b> om spillerne.</p> 
        <p>Jeg brugte <b>Named Entity Recognition og Sentiment Analysis</b> til at finde ud af, om der blev skrevet positivt eller negativt om de forskellige spillere.</p> 
        <p>Herefter fik jeg scouts og trænere i Brøndby til at interagere med de forskellige spillere ud fra forskellige kriterier for at kunne eksperimentere med <b>Recommendation Models</b> (som eksempelvis Netflix bruger til at anbefale film til dig).</p> 
        <p>Dette gav et datasæt med de forskellige spilleres popularitet på Twitter, samt hvordan scouts og trænere så dem ud fra specifikke kriterier. Jeg tilføjede selv lidt yderligere data om spillerne som alder, klub, kampe spillet osv.</p> 
        <p>Til sidste kunne jeg eksperimentere med Recommendation Models i form af <b>Content Based- og Collaborative-filtering samt Neural Networks</b>.</p> 
        <p>Konklusionen var, at metoden er meget interessant. Det var nogle spændende spillere, som kom frem ud fra de forskellige kriterier.</p> 
        <p>De mest lovende spillere ved denne metode var Andreas Schelderup, Viktor Kristiansen og Evander, som alle blev solgt indenfor 6 måneder til udlandet for omkring 100 millioner kr.</p> 
        <p>Specialet blev startet inden ChatGPT kom ud. Sprogmodellerne er så kraftfulde i dag, at metoden kun ville virke bedre.</p>
        </div>
    </div>
  );
};

export default Resume;
